import {DetailedReport, GetReportsResponse, Iapi} from "./Iapi";
import {InvokeCommand, LambdaClient} from "@aws-sdk/client-lambda";
import {Auth} from "aws-amplify";
import {ICredentials} from "aws-amplify/lib/Common/types/types";

enum ApiRequestType {
    getReports = "GET_REPORTS",
    getDetailedReport = "GET_DETAILED_REPORT",
    getDetailedReports = "GET_DETAILED_REPORTS",
}

interface BaseRequest {
    type: ApiRequestType
}

interface GetDetailedReportRequest extends BaseRequest {
    reportId: string,
}
interface GetReportsRequest extends BaseRequest {
    nextToken?: string
}

interface GetDetailedReportResult {
    report: DetailedReport
}

class AwsApi implements Iapi {
    private lambdaFunctionName: string = process.env.ORG_NAME ? `load-test-api-${process.env.ORG_NAME.toLowerCase()}` : "load-test-api-preprod";
    private region: string = process.env.AWS_REGION ? process.env.AWS_REGION : "us-west-2"
    async getDetailedReport(reportId: string): Promise<DetailedReport> {
        const request: GetDetailedReportRequest = {
            type: ApiRequestType.getDetailedReport,
            reportId: reportId,
        }
        const response = await this.makeLambdaApiCall<GetDetailedReportRequest, GetDetailedReportResult>(request);
        return response.report;
    }

    async getReports(nextToken?: string): Promise<GetReportsResponse> {
        const request: GetReportsRequest = {
            type: ApiRequestType.getReports,
            nextToken
        }
        return await this.makeLambdaApiCall<GetReportsRequest, GetReportsResponse>(request)
    }

    private async makeLambdaApiCall<RequestType, ResponseType>(payload: RequestType): Promise<ResponseType> {
        let creds: ICredentials;
        try {
            creds = await Auth.currentCredentials()
        } catch(e) {
            await Auth.signOut()
            throw new Error(`failed to authenticate, signing out: ${e}`)
        }
        const lambdaClient = new LambdaClient({
            region: this.region,
            credentials: creds
        })
        try {
            console.log("1")
            const request = new InvokeCommand({
                FunctionName: this.lambdaFunctionName,
                Payload: new TextEncoder().encode(JSON.stringify(payload)),
            })
            console.log("2")
            const resp = await lambdaClient.send(request)
            console.log("3", resp)
            // makes sure api call to inovke lambda was successful
            if (resp.StatusCode !== 200) {
                throw new Error(`failed to load perf tests, status: ${resp.StatusCode}, error: ${resp.FunctionError}`)
            }
            console.log("4")
            const decodedResponse = JSON.parse(new TextDecoder().decode(resp.Payload!))
            console.log("5", decodedResponse)
            // makes sure internally our lambda call was successful
            if (decodedResponse.statusCode && decodedResponse.statusCode !== 200) {
                throw new Error(`api call failed status: ${decodedResponse.statusCode}, message: ${decodedResponse.message}`)
            }
            console.log("6")

            return decodedResponse as ResponseType
        } finally {
            lambdaClient.destroy()
        }
    }
}

export const awsApi = new AwsApi();
