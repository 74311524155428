import React, {useEffect} from 'react';
import {Pane, Heading, Tooltip, Table, Text, Pre} from "evergreen-ui";
import {Doughnut, Line} from "react-chartjs-2";
import { useParams } from 'react-router-dom'
import {ReportStore} from "../store/report-store";
import {Loader} from "@aws-amplify/ui-react";
import {DetailedReport} from "../api";
import {
    createDonutChartDatasetFromRpcPerfData,
    generateLineChartLatencyDatasets,
    generateXAxisLabels,
} from "../lib/common";
type StatusCodeChartProps = {
    data: DetailedReport
}
function StatusCodeChart(props: StatusCodeChartProps) {
    const chartData = createDonutChartDatasetFromRpcPerfData(props.data)
    return (
        <>
            <Pane minWidth={400} maxWidth={500} alignItems='left'>
                <Heading size={600}>
                    Status Code Distribution
                </Heading>
                <Doughnut data={chartData.dataset} options={{
                    plugins: {
                        legend: {
                            display: true,
                            labels: {
                                color: 'rgb(255, 99, 132)'
                            },
                            position: 'bottom'
                        }
                    },
                }} />
            </Pane>
            <Pane flex={1} marginLeft={16} maxHeight={400} overflowY={'auto'}>
                {Object.entries(chartData.statusCodeDistribution).map(([key, value]) => (
                    <Table.Row key={`status-${key}`}>
                        <Table.TextCell textProps={{ size: 400 }} minWidth={200}>
                            {key}
                        </Table.TextCell>
                        <Table.TextCell isNumber>
                            {value.toString()}
                            <Tooltip content='Percent of all calls'>
                                <Text marginLeft={8} textDecoration='underline dotted'>
                                    {'(' + Number.parseFloat(String((value / chartData.totalRequests) * 100)).toFixed(2) + ' %)'}
                                </Text>
                            </Tooltip>
                        </Table.TextCell>
                    </Table.Row>
                ))}
            </Pane>
            <Pane flex={1} />
        </>

    )
}

type SelectedReportData = {
    data: DetailedReport
}

function LineChart(props: SelectedReportData) {
    const numArray = Array.from(Array(props.data.stats.length).keys())
    const labels = generateXAxisLabels(numArray, 500);
    const datasets = generateLineChartLatencyDatasets(props.data, ['rgb(75, 192, 192)', 'rgb(75, 24, 240)', 'red', 'green', 'orange'])
    return <Pane height={500}>
        <Line height={500} data={{labels, datasets}} options={{ responsive: true, maintainAspectRatio: false, scales: {
                x: { display: true, type: "linear", title: { display: true, text: "minutes"} },
                y: { display: true, type: "linear", title: { display: true, text: "Latency (ms)"} }
            }}}/>
    </Pane>
}

type Params = {
    id: string;
}

function LoadReport() {
    const reportStore = ReportStore.useContainer();
    const params = useParams<Params>();
    console.log("params", params);
    useEffect(() => {
        if (params.id) {
            reportStore.getDetailedReport(params.id).catch(e => console.error(e))
        } else {
            // navigate to 404
        }
    }, [])

    if (!reportStore.selectedReport) {
        return (
            <Pane>
                <Loader />
            </Pane>
        )
    }
    return (
        <Pane>
            <Pane display='flex' marginTop={16} alignItems='left'>
                <LineChart data={reportStore.selectedReport}/>
            </Pane>
            <Pane display='flex' marginTop={16} alignItems='left'>
                <StatusCodeChart data={reportStore.selectedReport}/>
            </Pane>
            <Pane>
                <Heading size={600}>
                    Config
                </Heading>
                <Pane background='tint2' marginTop={16}>
                    <Pre fontFamily='monospace' paddingX={16} paddingY={16}>
                        {reportStore.selectedReport.configFile}
                    </Pre>
                </Pane>
            </Pane>
        </Pane>
    );
}

export default LoadReport;
