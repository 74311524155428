import React, { useState } from "react"
import { createContainer } from "unstated-next"
import {toaster} from "evergreen-ui";
import {api} from '../api';
import {DetailedReport, ReportOverview} from "../api";

function useReportStore() {
    const [isFetching, setIsFetching] = useState(false);
    const [reports, setReports] = useState<ReportOverview[]>([]);
    const [nextReportsToken, setNextReportsToken] = useState<string | undefined>(undefined);
    const [selectedReport, setSelectedReport] = useState<DetailedReport>()

    const getReports = async (fetchNext?: boolean) => {
        setIsFetching(true);
        try {
            if (fetchNext) {
                const resp = await api.getReports(nextReportsToken);
                setReports((currReports) => [...currReports, ...resp.reports])
                setNextReportsToken(resp.nextToken)
            } else {
                const resp = await api.getReports();
                setReports(resp.reports)
                setNextReportsToken(resp.nextToken)
            }

        } catch (e) {
            toaster.danger(`failed to get reports: ${e}`)
        } finally {
            setIsFetching(false)
        }
    }
    const getDetailedReport = async (id: string) => {
        setIsFetching(true)
        try {
            const resp = await api.getDetailedReport(id);
            setSelectedReport(resp);
        } catch (e) {
            toaster.danger(`failed to get detailed report: ${e}`)
        } finally {
            setIsFetching(false);
        }
    }
    const hasMoreReports = !nextReportsToken
    return { reports, isFetching, getReports, selectedReport, hasMoreReports, getDetailedReport }
}

export const ReportStore = createContainer(useReportStore)
