import React from "react";
import {Amplify} from "aws-amplify";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import {withAuthenticator} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import {
  Pane,
  Button,
  Tab,
  Icon,
  Text,
  DashboardIcon,
} from "evergreen-ui";
import {CognitoUser} from "amazon-cognito-identity-js";
import {LoadReportList} from "./components/LoadReportList";
import LoadReport from './components/LoadReport';
import ReportComparison from "./components/ReportComparison";

Amplify.configure({
  Auth: {
    region: 'us-west-2',
    userPoolId: 'us-west-2_ivnyKqjZH',
    userPoolWebClientId: '2a2i3ur1rn1ort2bmsbc2vpfpn',
    identityPoolId: 'us-west-2:6ae20213-b35f-4e77-a128-a54097be50b8',
    mandatorySignIn: true,
    oauth: {
      domain: 'momento-alpha.auth.us-west-2.amazoncognito.com',
      scope: [
        'email',
          'openid',
        // 'aws.cognito.signin.user.admin'
      ],
      // redirectSignOut: 'https://admin.developer-mst-dev.preprod.a.momentohq.com/auth',
      redirectSignIn: 'https://admin.developer-mst-dev.preprod.a.momentohq.com/reports',
      // redirectSignIn: 'http://localhost:3000/auth',
      clientId: '914544654671-i61r4v4rgf9qs5iosnj2enm73tk6lvfu.apps.googleusercontent.com',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS"
  },
});

function Home() {
  const navigate = useNavigate();
  return (
      <Pane>
        <Pane display={'flex'} alignItems={'center'}>
          <Button marginRight={16} intent="none" onClick={() => navigate('/reports')}>
            Reports
          </Button>
          <Button marginRight={16} intent="none" onClick={() => navigate('/anothertool')}>
            Another Tool
          </Button>
        </Pane>
      </Pane>
  );
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}

export interface CognitoAttributes {
  email: string;
  phone_number: string;
  [key: string]: string;
}
/** Cognito User Interface */
export interface CognitoUserAmplify extends CognitoUser {
  username?: string;
  attributes?: CognitoAttributes;
}

type TabLinkProps = {
  to: string;
  icon: React.ElementType | JSX.Element;
  linkText: string;
}
const TabLink = (props: TabLinkProps) => (
    <Link to={props.to}  style={{ textDecoration: 'none' }}>
      <Tab height={36} paddingX={14}><Icon icon={props.icon} marginRight={12} /><Text size={400}>{props.linkText}</Text></Tab>
    </Link>
)

const App = () => {
  return (
      <Router>
        <Pane display='flex' paddingY={12} borderBottom>
          <Pane flex={1} alignItems='center' display='flex' marginLeft={8}>
            <TabLink to='/reports' linkText='REPORTS' icon={DashboardIcon} />
          </Pane>
        </Pane>
        <Pane paddingX={20} paddingY={12}>
          <Routes>
            <Route path="/auth" element={<LoadReportList />} />
            <Route path="/reports" element={<LoadReportList />} />
            <Route path="/report/:id" element={<LoadReport />} />
            <Route path="/report/:reportId1/:reportId2" element={<ReportComparison />} />
          </Routes>
        </Pane>
      </Router>
  );
};

export default withAuthenticator(App, { socialProviders: ['google'], hideSignUp: true });
