import React from 'react';
import ReactDOM from 'react-dom';
import {Chart, ArcElement, Legend, CategoryScale, LinearScale, PointElement, LineElement, BarElement} from 'chart.js'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ReportStore} from "./store/report-store";
import {ComparisonStore} from "./store/comparison-store";

Chart.register(
    ArcElement,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement
);

ReactDOM.render(
    <React.StrictMode>
        <ReportStore.Provider>
            <ComparisonStore.Provider>
                <App />
            </ComparisonStore.Provider>
        </ReportStore.Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
