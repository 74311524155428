import React, {useEffect, useState} from 'react';
import {
    Pane,
    Heading,
    Tooltip,
    Table,
    Text,
    Icon,
    FullCircleIcon,
    Strong,
    PercentageIcon, Pre
} from "evergreen-ui";
import {
    Link,
    Route,
    useParams,
} from "react-router-dom";
import {ComparisonStore} from "../store/comparison-store";
import {Loader} from "@aws-amplify/ui-react";
import { Line } from 'react-chartjs-2';
import {
    createComparisonLineChartProps,
    createReportSummary,
    ReportSummary
} from "../lib/common";

type Params = {
    reportId1: string;
    reportId2: string;
}

type ComparedReport = {
    p50: string;
    p90: string;
    p999: string;
}

function ReportComparison() {
    const comparisonStore = ComparisonStore.useContainer();
    const [x, setX] = useState<{report1Summary: ReportSummary, report2Summary: ReportSummary}>();
    const params = useParams<Params>();
    console.log("params", params);


    function toLocaleString (date: string | Date | undefined) {
        if (!date) {
            return '';
        }
        if (date instanceof Date) {
            return date.toLocaleString
        }

        const dateObj = new Date(date + '')
        return dateObj.toLocaleString()
    }

    const maxWidthLabel = 100;
    useEffect(() => {
        comparisonStore.compareReports({
            reportId1: params.reportId1!,
            reportId2: params.reportId2!
        }).catch(e => console.error(e))
    }, [])

    useEffect(() => {
        if (!comparisonStore.comparedReports?.report1) {
            return ;
        }

        const yolo = createReportSummary(comparisonStore.comparedReports?.report1!)
        console.log("hehehhrere", yolo)
        setX({
            report1Summary: yolo,
            report2Summary: createReportSummary(comparisonStore.comparedReports?.report2!)
        })
    }, [comparisonStore.comparedReports])

    if (comparisonStore.isFetching || !comparisonStore.comparedReports?.report1 || !comparisonStore.comparedReports?.report2) {
        return (
            <Pane>
                <Loader />
            </Pane>
        )
    }

    const color1 = "blue"
    const color2 = "orange"
    const labelStr = `Latency (ms)`

    return (
        <Pane marginTop={6}>
            <Pane>
                <Heading size={600}>REPORT COMPARISON</Heading>
            </Pane>

            <Pane marginTop={16} display='flex'>
                <Pane maxWidth={450}>
                    <Icon icon={FullCircleIcon} size={12} color={color1} marginRight={10} />
                    <Link to={`/report/${comparisonStore.comparedReports?.report1.id}`}>
                       <Text size={500} marginRight={8}>{comparisonStore.comparedReports?.report1.id}</Text>
                    </Link>
                    {/*<StatusBadge status={report1.status} />*/}
                    <Pane marginTop={8}>
                        <Text>
                            {toLocaleString(comparisonStore.comparedReports?.report1.startDate)}
                        </Text>
                    </Pane>
                </Pane>

                <Pane marginLeft={32} maxWidth={450}>
                    <Icon icon={FullCircleIcon} size={12} color={color2} marginRight={10} />
                    <Link to={`/report/${comparisonStore.comparedReports?.report2.id}`}>
                        <Text size={500} marginRight={8}>{comparisonStore.comparedReports?.report2.id}</Text>
                    </Link>
                    <Pane marginTop={8}>
                        <Text>
                            {toLocaleString(comparisonStore.comparedReports?.report2.startDate)}
                        </Text>
                    </Pane>
                </Pane>
            </Pane>

            <Pane marginTop={32} display='flex'>
                <Pane flex={4}>
                    <Line data={
                        createComparisonLineChartProps(comparisonStore.comparedReports.report1!, comparisonStore.comparedReports.report2!)
                    } options={{ responsive: true, scales: {
                        x: { display: true, type: "linear", title: { display: true, text: "minutes"} },
                        y: { display: true, type: "linear", title: { display: true, text: "Latency (ms)"} }
                    }}} />
                </Pane>
                <Pane flex={1} />
            </Pane>

            <Pane display='flex' marginTop={24} marginBottom={24}>

                <Pane flex={2}>
                    <Heading size={600}>
                        Summary
                    </Heading>

                    <Pane>
                        <Table.Row>
                            <Table.TextCell maxWidth={maxWidthLabel} />
                            <Table.TextCell>
                                <Tooltip content={comparisonStore.comparedReports?.report1.id}>
                                    <Text size={500} color={color1}>{comparisonStore.comparedReports?.report1.loadTestName}</Text>
                                </Tooltip>
                            </Table.TextCell>
                            <Table.TextCell>
                                <Tooltip content={comparisonStore.comparedReports?.report2.id}>
                                    <Text size={500} color={color2}>{comparisonStore.comparedReports?.report2.loadTestName}</Text>
                                </Tooltip>
                            </Table.TextCell>
                            <Table.TextCell></Table.TextCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.TextCell maxWidth={maxWidthLabel}>
                                <Strong>Requests</Strong>
                            </Table.TextCell>
                            <Table.TextCell isNumber>
                                {comparisonStore.comparedReports?.report1.stats.slice(-1)[0]["responses/total"]}
                            </Table.TextCell>
                            <Table.TextCell isNumber>
                                {comparisonStore.comparedReports?.report2.stats.slice(-1)[0]["responses/total"]}
                            </Table.TextCell>
                            <Table.TextCell />
                        </Table.Row>
                        <Table.Row>
                            <Table.TextCell maxWidth={maxWidthLabel}>
                                <Strong>Hit</Strong>
                            </Table.TextCell>
                            <Table.TextCell isNumber>
                                {comparisonStore.comparedReports?.report1.stats.slice(-1)[0]["responses/hit"]}
                            </Table.TextCell>
                            <Table.TextCell isNumber>
                                {comparisonStore.comparedReports?.report2.stats.slice(-1)[0]["responses/hit"]}
                            </Table.TextCell>
                            <Table.TextCell />
                        </Table.Row>
                        <Table.Row>
                            <Table.TextCell maxWidth={maxWidthLabel}>
                                <Strong>Miss</Strong>
                            </Table.TextCell>
                            <Table.TextCell isNumber>
                                {comparisonStore.comparedReports?.report1.stats.slice(-1)[0]["responses/miss"]}
                            </Table.TextCell>
                            <Table.TextCell isNumber>
                                {comparisonStore.comparedReports?.report2.stats.slice(-1)[0]["responses/miss"]}
                            </Table.TextCell>
                            <Table.TextCell />
                        </Table.Row>
                        <Table.Row>
                            <Table.TextCell maxWidth={maxWidthLabel}>
                                <Strong>Error</Strong>
                            </Table.TextCell>
                            <Table.TextCell isNumber>
                                {comparisonStore.comparedReports?.report1.stats.slice(-1)[0]["requests/error"]}
                            </Table.TextCell>
                            <Table.TextCell isNumber>
                                {comparisonStore.comparedReports?.report2.stats.slice(-1)[0]["requests/error"]}
                            </Table.TextCell>
                            <Table.TextCell />
                        </Table.Row>
                    </Pane>
                </Pane>

                <Pane flex={1} />
            </Pane>
            <Pane>
                <Heading size={600}>
                    Configs
                </Heading>
                <Pane display={'flex'} flexDirection={'row'} flexWrap={'nowrap'}>
                    <Pane overflowX={'auto'}>
                        <Heading size={500} color={color1}>
                            {comparisonStore.comparedReports?.report1.id}
                        </Heading>
                        <Pane background='tint2' marginTop={16} overflowX={'auto'} marginRight={8}>
                            <Pre fontFamily='monospace' paddingX={16} paddingY={16}>
                                {comparisonStore.comparedReports?.report1.configFile}
                            </Pre>
                        </Pane>
                    </Pane>
                    <Pane overflowX={'auto'}>
                        <Heading size={500} color={color2}>
                            {comparisonStore.comparedReports?.report2.id}
                        </Heading>
                        <Pane background='tint2' marginTop={16} overflowX={'auto'}>
                            <Pre fontFamily='monospace' paddingX={16} paddingY={16}>
                                {comparisonStore.comparedReports?.report2.configFile}
                            </Pre>
                        </Pane>
                    </Pane>
                </Pane>
            </Pane>
        </Pane>
    );
}

export default ReportComparison;
